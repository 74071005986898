<template>
	<div class="row">
		<div class="col-md-12 text-center" style="padding-bottom: 20px">
			<div v-if="hasPDf">
				<a-pagination v-model="actualPage" :total="numPages" :page-size="1" show-less-items />
				<pdf v-for="i in numPages" :key="i" :src="src" :page="i" style="display: none" v-show="i == actualPage" />
			</div>
			<div v-else>
				<img :src="fileURL" class="img-fluid" />
			</div>
		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
	name: 'fileViewerComponent',
	components: {
		pdf,
	},
	props: {
		fileURL: {
			type: String,
			default: '',
		},
	},
	computed: {
		hasPDf() {
			return this.fileURL.toLowerCase().split('.pdf').length == 2 ? true : false
		},
	},
	data() {
		return {
			src: pdf.createLoadingTask(this.fileURL),
			numPages: undefined,
			actualPage: 1,
		}
	},
	mounted() {
		this.src.promise.then((pdf) => {
			this.numPages = pdf.numPages
		})
	},
}
</script>