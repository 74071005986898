<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-info" icon="plus" @click="createBinnacle" v-if="allowNewRecord">Nuevo registro</a-button>
				<a-button class="btn btn-danger" icon="close" @click="closeBinnacle" v-else-if="allowCloseRecord">Cierre de registro</a-button>
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="localData">
					<div class="text-left" slot="initial_ride_date" slot-scope="record">
						{{ moment(record.initial_ride_date).format('DD-MM-YYYY HH:mm') }} hrs.
					</div>
					<div class="text-left" slot="end_ride_date" slot-scope="record">
						{{ record.end_ride_date ? `${moment(record.end_ride_date).format('DD-MM-YYYY HH:mm')} hrs.` : '' }}
					</div>
					<div class="text-justify" slot="activities" slot-scope="record">
						{{ record.activities }}
					</div>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	computed: {
		...mapGetters('binnacle', ['spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('user', ['user']),
		allowNewRecord() {
			return this.localData.length && this.localData[0].end_ride_date
		},
		allowCloseRecord() {
			return this.localData.length && this.localData[0].user_id == this.user.user_id && !this.localData[0].end_ride_date
		},
	},
	data() {
		return {
			localData: [],
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Inicio',
					scopedSlots: { customRender: 'initial_ride_date' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Fin',
					scopedSlots: { customRender: 'end_ride_date' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Colaborador',
					dataIndex: 'fullName',
					align: 'center',
					width: '15%',
				},
				{
					title: 'Actividades',
					scopedSlots: { customRender: 'activities' },
					align: 'center',
				},
			],
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		initModule() {
			this.$store.dispatch('binnacle/GET', {}).then(response => {
				this.localData = _.cloneDeep(utilities.objectValidate(response, 'data', []))
			})
		},
		createBinnacle() {
			Swal.fire({
				title: 'Atención',
				text: '¿Confirmas iniciar un nuevo registro de bitácora?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('binnacle/CREATE', {}).then(() => {
						this.initModule()
					})
				}
			})

		},
		closeBinnacle() {
			Swal.fire({
				title: 'Atención',
				text: 'Describe brevemente las actividades realizadas',
				icon: 'info',
				input: 'textarea',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('binnacle/CLOSE', {
						id: this.localData[0].id,
						payload: {
							activities: result.value,
						},
					}).then(() => {
						this.initModule()
					})
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>